<template>
  <div v-if="!loading" class="tw-w-96 tw-m-auto">
    <p class="tw-text-center">{{this.vendor}} has invited you to leave a review</p>

    <v-form ref="form" v-model="valid" :lazy-validation="lazy"  @submit.prevent="create">
      <v-card flat elevation="0">
        <v-card-title>
          <v-input :value="item.rating" :rules="[rules.required]">
            <v-spacer/>
            <v-rating
                v-model="item.rating"
                color="red"
                background-color="grey darken-1"
                emptyIcon='mdi-heart-outline'
                fullIcon='mdi-heart'
                halfIcon='mdi-heart-half-full'
                half-increments
                hover
                large
            ></v-rating>
          </v-input>
          <v-spacer/>

        </v-card-title>
        <v-card-text>

          <v-textarea
              :rules="[rules.required,rules.maxlength(1500)]"
              v-model="item.description"
              :label="$t('rating.comment')"
              outlined
              counter
          >
          </v-textarea>


          <button :disabled="!valid"
                  class="disabled:tw-opacity-50 tw-flex tw-justify-center tw-w-full tw-px-6 tw-py-4 tw-border tw-border-transparent tw-text-base tw-font-medium tw-rounded-xl tw-text-white tw-bg-gray-n4 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-gray-n4"
          >
            {{ $t('rating.submit') }}
          </button>
        </v-card-text>

      </v-card>
    </v-form>

  </div>

</template>

<script>
export default {
  data() {
    return {
      loading: false,
      vendor: null,
      averageRating: null,
      valid: false,
      lazy: false,
      item:{
        email: this.$route.params.email,
        description: null,
        rating: null,
        rating_collector_id: this.$route.params.id,
        vendor_id: null,
      },
      rules: {
        required: v => !!v || 'This field is required',
        maxlength: len => v => (v || '').length <= len || `Invalid character max length ${len}`,
      }
    }
  },
  mounted() {
    this.getVendor();
  },
  methods: {
    getVendor() {
      this.loading = true;
      let uri = '/api/ratingCollector/' + this.item.rating_collector_id;
      this.$http.get(uri)
      .then(response => {
        this.vendor = response.data.data.name;
        this.avgRating = response.data.data.averageRating;
        this.loading = false;
        this.item.vendor_id = response.data.data.id;
      });
    },
    async create() {

      this.$refs.form.validate();
      if (!this.valid) return;

      let uri = '/api/vendor-store-review';

      this.$http.post(uri, this.item)
      .then((response) => {
        this.$toast.success(response.data.message, "Thank you for your feedback!");
        //this.$router.go('welcome');
        this.$router.push({name: 'reviews.show', params: {id: this.item.vendor_id}});
      });
    },
  }
}
</script>
